import React, { memo, useCallback, useContext, useEffect } from "react";
import { useStyles } from "./styles";
import { useParams } from "react-router-dom";
import { CustomBox } from "../../library/custom/CustomBox/CustomBox";
import { CustomGrid } from "../../library/custom/CustomGrid/CustomGrid";
import { TyxitIcon } from "../../library/icons/TyxitIcon";
import { CustomTypography } from "../../library/custom/CustomTypography/CustomTypography";
import { CustomButton } from "../../library/custom/CustomButton/CustomButton";
import { CustomDivider } from "../../library/custom/CustomDivider/CustomDivider";
import { DownloadContext } from "../../contexts/DownloadContext/DownloadContext";
import { UserPlatform } from "../../contexts/DownloadContext/types";
import { DownloadForDarwin } from "../../components/Downloading/DownloadForDarwin/DownloadForDarwin";
import { DownloadForWindows } from "../../components/Downloading/DownloadForWindows/DownloadForWindows";

const DownloadingPage = memo(() => {
  const { code } = useParams();

  const {
    values: { userPlatform },
  } = useContext(DownloadContext);

  const classes = useStyles();

  const handleOpenApp = useCallback(() => {
    if (!code) {
      return;
    }

    window.location.replace(`tyxit://./join?code=${code}`);
  }, [code]);

  useEffect(() => {
    handleOpenApp();
  }, [handleOpenApp]);

  return (
    <CustomGrid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <CustomGrid item container>
        <TyxitIcon className={classes.tyxitIcon} />
      </CustomGrid>
      <CustomGrid
        item
        container
        alignItems="center"
        direction="column"
        className={classes.content}
      >
        <CustomGrid container direction="column" alignItems="center" textAlign="center">
          <CustomBox>
            <CustomTypography
              variant="h2"
              fontWeight={400}
              nameSpace="downloadingPage"
              translation="joinMeeting.click"
              display="inline"
            />
            <CustomTypography
              variant="h2"
              nameSpace="downloadingPage"
              translation="joinMeeting.openApp"
              display="inline"
            />
            <CustomTypography
              variant="h2"
              fontWeight={400}
              nameSpace="downloadingPage"
              translation="joinMeeting.inBrowserDialog"
              display="inline"
            />
          </CustomBox>
          <CustomBox className={classes.description}>
            <CustomTypography
              variant="h2"
              fontWeight={400}
              nameSpace="downloadingPage"
              translation="openDialog.ifYouDontSeeJoinMeeting"
              display="inline"
            />
            <CustomTypography
              variant="h2"
              nameSpace="downloadingPage"
              translation="openDialog.joinMeeting"
              display="inline"
            />
          </CustomBox>
          <CustomButton
            variant="contained"
            nameSpace="downloadingPage"
            translation="joinMeetingButton"
            onClick={handleOpenApp}
            className={classes.button}
          />
          <CustomDivider className={classes.divider} />
          <CustomBox className={classes.downloadTitle}>
            <CustomTypography
              variant="h2"
              display="inline"
              fontWeight="400"
              nameSpace="downloadingPage"
              translation="download.title"
            />
            <CustomTypography
              variant="h2"
              display="inline"
              nameSpace="downloadingPage"
              translation="download.tyxit"
            />
            <CustomTypography
              variant="h2"
              display="inline"
              fontWeight="400"
              nameSpace="downloadingPage"
              translation="download.continue"
            />
          </CustomBox>
          {userPlatform === UserPlatform.windows && <DownloadForWindows />}
          {userPlatform === UserPlatform.darwin && <DownloadForDarwin />}
        </CustomGrid>
      </CustomGrid>
    </CustomGrid>
  );
});

export { DownloadingPage };
