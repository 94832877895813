import { createTheme, Theme } from "@mui/material";
import { rem } from "../utils/css/rem";

const componentsTheme = (theme: Theme): Theme =>
  createTheme({
    ...theme,
    components: {
      MuiSvgIcon: {
        defaultProps: {
          fill: "none",
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: rem(8),
            textTransform: "none",
          },
        },
        variants: [
          {
            props: {
              size: "medium",
            },
            style: {
              height: rem(52),
            },
          },
          {
            props: {
              color: "primary",
            },
            style: {
              backgroundColor: theme.palette.buttons.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.buttons.primary.hover,
              },
              "&:active": {
                backgroundColor: theme.palette.buttons.primary.active,
              },
              "&.Mui-disabled": {
                background: theme.palette.buttons.primary.disabled,
              },
            },
          },
        ],
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
        variants: [
          {
            props: {
              color: "primary",
            },
            style: {
              cursor: "pointer",
              color: theme.palette.link.primary.main,
              "&:hover": {
                color: theme.palette.link.primary.hover,
              },
              "&:active": {
                color: theme.palette.link.primary.active,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiGrid: {
        defaultProps: {
          flexWrap: "nowrap",
        },
      },
    },
  });

export { componentsTheme };
