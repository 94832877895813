import React, { memo, useCallback, useContext } from "react";
import { CustomButton } from "../../../library/custom/CustomButton/CustomButton";
import { CustomGrid } from "../../../library/custom/CustomGrid/CustomGrid";
import { CustomLink } from "../../../library/custom/CustomLink/CustomLink";
import { CustomTypography } from "../../../library/custom/CustomTypography/CustomTypography";
import { AppleIcon } from "../../../library/icons/AppleIcon";
import { useStyles } from "./styles";
import { DownloadContext } from "../../../contexts/DownloadContext/DownloadContext";
import { AppTargetPlatform } from "../../../contexts/DownloadContext/types";

const DownloadForDarwin = memo(() => {
  const {
    actions: { onRequestDownload },
  } = useContext(DownloadContext);

  const classes = useStyles();

  const handleClickMacIntelDownload = useCallback(() => {
    onRequestDownload({ target: AppTargetPlatform.darwinX64 });
  }, []);

  const handleClickMacAppleDownload = useCallback(() => {
    onRequestDownload({ target: AppTargetPlatform.darwinArm64 });
  }, []);

  const handleClickWinDownload = useCallback(() => {
    onRequestDownload({ target: AppTargetPlatform.windowsX64 });
  }, []);

  return (
    <CustomGrid container direction="column" gap={6.75} alignItems="center">
      <CustomGrid item container gap={6.25} width="fit-content">
        <CustomButton
          variant="contained"
          nameSpace="downloadingPage"
          translation="download.platforms.macIntel"
          startIcon={<AppleIcon />}
          onClick={handleClickMacIntelDownload}
          className={classes.button}
        />
        <CustomButton
          variant="contained"
          nameSpace="downloadingPage"
          translation="download.platforms.macApple"
          startIcon={<AppleIcon />}
          onClick={handleClickMacAppleDownload}
          className={classes.button}
        />
      </CustomGrid>
      <CustomGrid item container width="fit-content">
        <CustomTypography
          whiteSpace="break-spaces"
          nameSpace="downloadingPage"
          translation="download.alsoAvailable"
        />
        <CustomLink color="primary">
          <CustomTypography
            nameSpace="downloadingPage"
            color="inherit"
            translation="download.platforms.windows"
            onClick={handleClickWinDownload}
          />
        </CustomLink>
      </CustomGrid>
    </CustomGrid>
  );
});

export { DownloadForDarwin };
