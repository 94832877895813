import React, { memo } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import { DownloadingPage } from "../containers/DownloadingPage/DownloadingPage";

const Routes = memo(() => {
  return (
    <Switch>
      <Route path="/:code" element={<DownloadingPage />} />
    </Switch>
  );
});

export { Routes };
