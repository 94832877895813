/* eslint-disable max-len */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React, { memo } from "react";

const TyxitIcon = memo((props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 293 58" {...props}>
      <path
        d="M120.24 4.53461e-06H100.91C100.801 0.00119601 100.695 0.031499 100.601 0.0877687C100.508 0.144038 100.432 0.224224 100.38 0.320005L89.45 20.6C89.131 21.1908 88.8439 21.7984 88.59 22.42C88.5235 22.5843 88.4095 22.725 88.2625 22.8241C88.1155 22.9232 87.9423 22.9761 87.765 22.9761C87.5877 22.9761 87.4145 22.9232 87.2675 22.8241C87.1206 22.725 87.0065 22.5843 86.94 22.42C86.6861 21.7984 86.399 21.1909 86.08 20.6L75.16 0.320005C75.1075 0.222736 75.0294 0.141582 74.9343 0.0852176C74.8392 0.0288535 74.7306 -0.000600603 74.62 4.53461e-06H55.22C55.0923 0.001121 54.9687 0.0451 54.8689 0.124888C54.7692 0.204675 54.6991 0.315647 54.67 0.440005C54.6407 0.611452 54.6802 0.787547 54.78 0.930005L76.38 34.93C78.282 37.9179 79.2884 41.3881 79.28 44.93V57.39C79.28 57.5518 79.3443 57.7069 79.4587 57.8213C79.5731 57.9357 79.7282 58 79.89 58H95.65C95.8118 58 95.9669 57.9357 96.0813 57.8213C96.1957 57.7069 96.26 57.5518 96.26 57.39V44.88C96.2516 41.3381 97.258 37.8679 99.16 34.88L120.8 0.880005C120.868 0.772314 120.899 0.644821 120.886 0.517917C120.873 0.391014 120.818 0.272032 120.73 0.180005C120.665 0.11826 120.588 0.0704508 120.504 0.0395014C120.419 0.00855204 120.33 -0.00488752 120.24 4.53461e-06Z"
        fill="currentColor"
      />
      <path
        d="M226.5 0H209.61C209.273 0 209 0.273106 209 0.61V57.39C209 57.7269 209.273 58 209.61 58H226.5C226.837 58 227.11 57.7269 227.11 57.39V0.61C227.11 0.273106 226.837 0 226.5 0Z"
        fill="currentColor"
      />
      <path
        d="M292.33 0H242.53C242.368 0 242.213 0.0642677 242.099 0.178665C241.984 0.293062 241.92 0.448218 241.92 0.61V14.34C241.92 14.5018 241.984 14.6569 242.099 14.7713C242.213 14.8857 242.368 14.95 242.53 14.95H257.53C257.766 14.95 257.992 15.0438 258.159 15.2107C258.326 15.3776 258.42 15.604 258.42 15.84V57.39C258.42 57.5467 258.48 57.6975 258.588 57.8111C258.696 57.9246 258.843 57.9923 259 58H275.82C275.982 58 276.137 57.9357 276.251 57.8213C276.366 57.7069 276.43 57.5518 276.43 57.39V15.84C276.43 15.604 276.524 15.3776 276.691 15.2107C276.858 15.0438 277.084 14.95 277.32 14.95H292.32C292.482 14.95 292.637 14.8857 292.751 14.7713C292.866 14.6569 292.93 14.5018 292.93 14.34V0.61C292.93 0.449936 292.867 0.296276 292.755 0.182162C292.643 0.0680482 292.49 0.002624 292.33 0V0Z"
        fill="currentColor"
      />
      <path
        d="M50.41 8.19789e-05H0.61C0.448218 8.19789e-05 0.293062 0.0643497 0.178665 0.178747C0.0642677 0.293144 0 0.4483 0 0.610082L0 14.3401C0 14.5019 0.0642677 14.657 0.178665 14.7714C0.293062 14.8858 0.448218 14.9501 0.61 14.9501H15.61C15.846 14.9501 16.0724 15.0438 16.2393 15.2108C16.4062 15.3777 16.5 15.604 16.5 15.8401V57.3901C16.5 57.5519 16.5643 57.707 16.6787 57.8214C16.7931 57.9358 16.9482 58.0001 17.11 58.0001H33.92C34.0818 58.0001 34.2369 57.9358 34.3513 57.8214C34.4657 57.707 34.53 57.5519 34.53 57.3901V15.8401C34.53 15.604 34.6238 15.3777 34.7907 15.2108C34.9576 15.0438 35.184 14.9501 35.42 14.9501H50.42C50.5818 14.9501 50.7369 14.8858 50.8513 14.7714C50.9657 14.657 51.03 14.5019 51.03 14.3401V0.610082C51.03 0.529127 51.0139 0.44898 50.9826 0.374312C50.9513 0.299644 50.9055 0.231949 50.8478 0.175172C50.7901 0.118396 50.7217 0.0736742 50.6465 0.0436135C50.5713 0.0135529 50.4909 -0.00124515 50.41 8.19789e-05Z"
        fill="currentColor"
      />
      <path
        d="M175.29 36.5302C171.16 32.4983 165.617 30.2412 159.845 30.2412C154.073 30.2412 148.53 32.4983 144.4 36.5302C139.15 41.7202 124 57.1202 124 57.1202C123.51 57.6102 123.68 58.0002 124.37 58.0002H145C145.13 58 145.256 57.9499 145.35 57.8602L155.41 47.8602C155.987 47.2648 156.678 46.7913 157.441 46.468C158.205 46.1447 159.026 45.9781 159.855 45.9781C160.684 45.9781 161.505 46.1447 162.269 46.468C163.032 46.7913 163.723 47.2648 164.3 47.8602L174.36 57.8602C174.455 57.9499 174.58 58 174.71 58.0002H195.38C196.07 58.0002 196.23 57.6002 195.75 57.1202C195.74 57.1202 180.54 41.7202 175.29 36.5302Z"
        fill="currentColor"
      />
      <path
        d="M195.37 0H174.7C174.57 0.000255322 174.445 0.0503471 174.35 0.14L164.29 10.14C163.713 10.7354 163.022 11.2089 162.259 11.5322C161.495 11.8555 160.674 12.0221 159.845 12.0221C159.016 12.0221 158.195 11.8555 157.431 11.5322C156.668 11.2089 155.977 10.7354 155.4 10.14L145.34 0.14C145.248 0.0526804 145.127 0.00276698 145 0L124.32 0C123.63 0 123.47 0.4 123.95 0.88C123.95 0.88 139.14 16.28 144.4 21.47C148.53 25.5019 154.073 27.759 159.845 27.759C165.617 27.759 171.16 25.5019 175.29 21.47C180.54 16.28 195.74 0.88 195.74 0.88C196.22 0.4 196.06 0 195.37 0Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});

export { TyxitIcon };
