import createPalette from "@mui/material/styles/createPalette";

const palette = createPalette({
  buttons: {
    primary: {
      main: "#FAA603",
      hover: "#FB9600",
      active: "#F18200",
      disabled: "#FFFFFF0D",
    },
  },
  link: {
    primary: {
      main: "#FAA603",
      hover: "#FB9600",
      active: "#FB9600",
    },
  },
  background: {
    default: "#ECECEC",
  },
});

export { palette };
