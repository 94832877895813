import { rem } from "../../utils/css/rem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    maxWidth: "100vw",
    maxHeight: "100vh",
    padding: rem(40, 40, 60, 40),
    backgroundImage: "url('/images/download-page-bg.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: theme.palette.background.default,
  },
  content: {
    width: "100%",
    position: "relative",
  },
  header: {
    width: "100%",
  },
  tyxitIcon: {
    width: rem(104),
    height: rem(20),
  },
  description: {
    margin: rem(16, 0, 40),
  },
  button: {
    width: rem(234),
  },
  divider: {
    width: "100%",
    maxWidth: rem(984),
    margin: rem(64, 0, 60),
  },
  downloadTitle: {
    margin: rem(0, 0, 36, 0),
  },
}));

export { useStyles };
